// ----------------------------------------------------------------------
import ProximaNovaRegularOTF from "../../assets/fonts/proxima-nova/proximanova-font.otf";

export default function CssBaseline() {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "ProximaNova",
                    src: `url(${ProximaNovaRegularOTF}) format("opentype")`,
                    fontWeight: 400,
                    fontStyle: "normal",
                },
                html: {
                    width: "100%",
                    height: "100%",

                    "*": {
                        "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "transparent !important",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "transparent",
                            borderRadius: "10px",
                        },

                        "&:hover::-webkit-scrollbar-thumb": {
                            backgroundColor: "#99999966 !important",
                        },
                        ".scroll-thumb-visible": {
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#99999966 !important",
                            },
                        },
                    },
                },
                body: {
                    width: "100%",
                    height: "100%",

                    "& .Toastify__toast-container--top-center": {
                        width: "500px",
                    },
                },
                "#root": {
                    width: "100%",
                    height: "100%",
                },
            },
        },
    };
}
